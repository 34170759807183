import React from "react"
import { graphql, Link } from "gatsby"
import * as PropTypes from "prop-types"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import { Breadcrum, SEO, PostHeader, Type, LastUpdated, Set, SocialLinks } from "../../components/index"
import * as styles from './index.module.scss';
import * as postStyles from '../../components/posts/posts.module.scss'
import * as tokenListStyles from '../../templates/token-list-page.module.scss'
import * as setStyles from '../../components/token/sets/sets.module.scss'
import { tokenTypeType, allPostsType } from '../../types'
import { getDefaultPostData } from '../../utils/structuredData'

class MissingTokensPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const { title, route } = props.data.allPostsJson.edges[0].node;
    this.breadcrumPages = [
      {
        text: 'Posts',
        to: '/posts/'
      },
      {
        text: title,
        active: true,
        to: route
      }
    ];
  }

  render () {
    const { missingTokens, allPostsJson } = this.props.data;
    const { 
      title, author, date, shortDescription, imgSrc, theme, route, contentImages, formattedDate,
      socialImgSrc: { childImageSharp: { fluid: { src: socialSrc } } }
    } = allPostsJson.edges[0].node;
    let currentLetter = '';
    return (
      <>
        <SEO title={title} description={shortDescription} pageUrl={route} imgSrc={socialSrc} />
        <Helmet>
          <script type="application/ld+json">{getDefaultPostData({
            route: route, title: title, 
            shortDescription: shortDescription, date: date,
            keywords: ['missing', 'proxy', 'forgotten', 'proxies', 'collection', 'lost', 'lore'],
            img: socialSrc,
            modifiedDate: date
          })}
          </script>
        </Helmet>
        <Breadcrum pages={this.breadcrumPages} />
        <article className={postStyles.article}>
          <PostHeader
            imgSrc={imgSrc}
            title={title}
            author={author}
            date={formattedDate}
            theme={theme}
          /> 
          <SocialLinks title={title} />
          <div className={postStyles.body}>
            <p>In modern releases of Magic: the Gathering any token card that can be created by a Magic card is printed and inserted at random in booster packs for that set. This wasn't always the case. Older sets contained cards that created tokens but the corresponding token card was never officially printed by Wizards of the Coast.</p>
            <p>Magic: the Gathering has <span className="big">{missingTokens.totalCount}</span> of these missing tokens. Popular examples of these include:</p>
            <Img fluid={contentImages[1].childImageSharp.fluid} alt={title} className={styles.contentImg} />
            <p>1. 2/2 green Ape created by Money Cage. <br/> 2. 2/2 colorless Spawn token created by the popular artifact token generator Spawning Pit.</p>
            <Img fluid={contentImages[0].childImageSharp.fluid} alt={title} className={styles.contentImg} />
            <p>
            3. The unusual 0/1 blue Starfish, created by Spiny Starfish.<br />4. 1/1 green Hippo created by Phelddagrif, a popular budget commander in EDH decks.</p>
            <p>Reprint sets such as{` `}
            <Link to={`/tokens/cma/`}>
              <Set id="cma" size="sm" />Commander Anthology
            </Link>
            {` `}and{` `}
            <Link to={`/tokens/uma/`}>
              <Set id="uma" size="sm" />Ultimate Masters
            </Link>{` `}
            are slowing reprinting some of the old token creating cards and for the first time printing the referencing tokens along with them. Until we have official tokens for each of the {` ${missingTokens.totalCount} `} missing tokens, we have sourced <span className={setStyles.proxy}>PROXY</span> tokens for them all and listed below!</p>
            <LastUpdated />
            <h2>An A-Z list of proxies for Magic's missing tokens:</h2>
            <ul className={postStyles.list}>
              {
                missingTokens.edges.map((tokenType) => {
                  const { 
                    id, name
                  } = tokenType.node;
                  const firstLetter = name.charAt(0)
                  const showLetter = firstLetter !== currentLetter;
                  if(showLetter) {
                    currentLetter = firstLetter;
                  }
                  return (
                    <li key={id}>
                      {
                        showLetter && (
                          <div className={tokenListStyles.letter}>{firstLetter}</div>
                        )
                      }
                      <Type compact token={tokenType.node} /> 
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </article>
      </>
    )
  }
}

MissingTokensPage.propTypes = {
  data: PropTypes.shape({
    allPostsJson: allPostsType.isRequired,
    missingTokens: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          token: PropTypes.shape({
            ...tokenTypeType
          })
        }),
      })).isRequired,
    }).isRequired,
  }).isRequired
}

export const missingTokensPageQuery = graphql`
  query MissingTokensPageQuery {
      allPostsJson(filter: {
        id: {
          eq: "post3"
        }
      }) {
        edges {
          node {
            id
            title
            author
            route
            shortDescription
            imgSrc {
              childImageSharp {
                fluid(maxWidth: 270) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            socialImgSrc {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            theme
            date
            formattedDate: date(formatString: "MMMM Do, YYYY")
            contentImages {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                  base64
                }
              }
            }
          }
        }
      }
      missingTokens: allTypesJson(filter: {
        proxy: {
          eq: true
        }
      }, sort: {fields: [id], order: ASC}) {
        totalCount
        edges {
          node {
            id
            name
            color
            power
            toughness
            text
            types
            subTypes
            sources
            tokens {
              id
              artist
              imgSrc {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    base64
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  `

export default MissingTokensPage
